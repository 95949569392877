<template>
  <div class="d-flex justify-start white">
    <v-toolbar
      flat
      floating
      class="flex-grow-0 mr-2"
    >
      <v-toolbar-title>
        {{ $t('support.searchTickets') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-form
      data-cy="support-history-ticket-table"
      @submit.prevent="handleSearch"
    >
      <v-text-field
        v-model="searched"
        append-icon="mdi-magnify"
        type="text"
        clearable
        hide-details
        @click:append="handleSearch"
        @click:clear="handleClear"
      />
    </v-form>
  </div>
</template>

<script>
const MIN_SEARCH_LENGTH = 3

export default {
  data () {
    return {
      searched: ''
    }
  },
  computed: {
    clientId () {
      return this.$route.params.clientId
    }
  },
  methods: {
    handleSearch () {
      if (this.searched.length === 0 || this.searched.length >= MIN_SEARCH_LENGTH) {
        this.$store.dispatch('support/getTickets', {
          clientId: this.clientId, searchParams: { searchTerm: this.searched }
        })
      }
    },
    handleClear () {
      this.$store.dispatch('support/getTickets', { clientId: this.clientId })
    }
  }
}
</script>
